import { APP_API_URL } from 'utils/constants';
import axios from 'axios';
import moment from 'moment-timezone';
const axiosService = axios.create({
  baseURL: APP_API_URL + '/alerts',
  headers: {
    'timezone': moment.tz.guess()
  } as never,
  withCredentials: true,
});

export const GETalert = async (id: number) => {
  const { data } = await axiosService.get(id.toString());
  return data;
};

export const GETalertCount = async () => {
  const { data } = await axiosService.get(`site/count`);
  return data;
};

export async function PATCHalerts(id: number, payload) {
  try {
    const res = await axiosService.patch('' + id, payload);
    return res.data;
  } catch (err) {
    throw err;
  }
}

export async function POSTalertsFilterd(payload) {
  try {
    const res = await axiosService.post('filtered', payload);
    return res.data;
  } catch (err) {
    return err;
  }
}

export async function PATCHupdateActions(id, payload) {
  try {
    const res = await axiosService.patch('updateActions/' + id, payload);
    return res.data;
  } catch (err) {
    throw err;
  }
}
