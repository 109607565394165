import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import Buttonui from '../Button';
import { useStyles } from './styles';

const IdleDialog = ({ open, handleConfirm }) => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialogBox}
      >
        <DialogTitle className={classes.title} id="alert-dialog-title">
          Idle
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className={classes.description}
            id="alert-dialog-description"
          >
            You have been idle for 30 minutes, please login again.
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.footer}>
          <Buttonui
            label={'login'}
            className={classes.button1}
            onClick={handleConfirm}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default IdleDialog;
