export const APP_VERSION = process.env.REACT_APP_VERSION || '';
export const APP_GOOGLE_MAP_API_KEY =
  process.env.REACT_APP_GOOGLE_MAP_API_KEY || '';
export const APP_DEFAULT_REFRESH_TIME_OUT =
  process.env.REACT_APP_DEFAULT_REFRESH_TIME_OUT || 30000;
export const APP_PUBLIC_URL = process.env.PUBLIC_URL || '';
export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';
export const IS_DEBUG_MODE = process.env.REACT_APP_IS_DEBUG_MODE === 'true';
// export const APP_API_URL = IS_PRODUCTION
//   ? process.env.REACT_APP_API_URL_PROD
//   : process.env.REACT_APP_API_URL_DEV;
export const APP_API_URL = process.env.REACT_APP_API_URL
// process.env.REACT_APP_API_URL || 'http://localhost:3001';
// export const APP_API_URL =
// process.env.REACT_APP_API_URL || 'https://api.itappt.com';
export const LOCK_ICONS = {
  red: '/locks/lock-red.png',
  green: '/locks/lock-green.png',
  gray: '/locks/lock-gray.png',
};

export const MENU_SIZE = 310;
export const DEFAULT_MAP_CENTER = {
  lat: parseFloat(process.env.REACT_APP_DEFAULT_LATITUDE || '0'),
  lng: parseFloat(process.env.REACT_APP_DEFAULT_LONGITUDE || '0'),
};
