/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ForgotPassword } from './ForgotPassword/Loadable';
import { LoginPage } from './LoginPage/Loadable';

export function AuthPage({ match }) {
  return (
    <>
      <Switch>
        <Route exact path={match.url} component={LoginPage} />
        <Route
          exact
          path={`${match.url}/forgot-password`}
          component={ForgotPassword}
        />
      </Switch>
    </>
  );
}
