import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 10,
    position: 'absolute',
    margin: '0 auto',
    wordSpacing: 'nowrap',
    color: '#FFF',
    fontWeight: 600,
  },
  batteryRoot: {
    position: 'relative',
    borderRadius: 4,
    marginRight: 5,
    marginLeft: 4,
    textAlign: 'center',
  },
}));

export const SanitizerRoundedIcon = () => {
  const styles = useStyles();

  return (
    <div className={styles.batteryRoot}>
      <svg
        width="16"
        height="18"
        viewBox="0 0 16 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9.91665 4.41667C9.91665 3.71667 11.1666 2.33334 11.1666 2.33334C11.1666 2.33334 12.4166 3.71667 12.4166 4.41667C12.4166 5.10834 11.8583 5.66667 11.1666 5.66667C10.475 5.66667 9.91665 5.10834 9.91665 4.41667ZM13.25 11.5C14.4 11.5 15.3333 10.5667 15.3333 9.41667C15.3333 8.02501 13.25 5.66667 13.25 5.66667C13.25 5.66667 11.1666 8.02501 11.1666 9.41667C11.1666 10.5667 12.1 11.5 13.25 11.5ZM7.83331 10.6667H6.16665V9.00001H4.49998V10.6667H2.83331V12.3333H4.49998V14H6.16665V12.3333H7.83331V10.6667ZM10.3333 9.00001V17.3333H0.333313V9.00001C0.333313 6.52501 2.13331 4.47501 4.49998 4.07501V2.33334H2.83331V0.666672H7.83331C8.77498 0.666672 9.62498 0.991672 10.325 1.50834L9.13331 2.70001C8.74998 2.47501 8.30831 2.33334 7.83331 2.33334H6.16665V4.07501C8.53331 4.47501 10.3333 6.52501 10.3333 9.00001Z" />
      </svg>
    </div>
  );
};
export default SanitizerRoundedIcon;
