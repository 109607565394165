import React, { FC, useEffect } from "react";
import { MENU_SIZE } from "utils/constants";
import { Link, useHistory } from "react-router-dom";
import {
  Badge,
  Button,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import MoreVert from "@material-ui/icons/MoreVert";
import NotificationIcon from "@material-ui/icons/Notifications";
import { LogOut } from "services/api/auth";
import { GETalertCount } from "services/api/alerts";
import { useAuth } from "services/hooks/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#FFF",
    color: "#ccc",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.05)",
    position: "fixed",
    top: 0,
    right: 0,
    zIndex: 10,
    width: `calc(100vw - ${MENU_SIZE}px)`,
  },
  navWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  searchWrapper: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #F86664",
    padding: "8px",
    minWidth: "260px",
    borderRadius: "1.5rem",
  },
  searchIconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: `0px ${theme.spacing(0.5)}px`,
    color: "#F86664",
  },
  searchInput: {
    border: "0px",
    "&:focus": {
      outline: "none",
    },
    "&:focus + $searchWrapper": {
      borderColor: theme.palette.primary,
    },
  },
  navActionsArea: {
    display: "flex",
    alignItems: "center",
  },
  navAvtar: {
    margin: `0 ${theme.spacing(2)}px`,
  },
  btnLogout: {
    position: "relative",
    "& .MuiButton-root": {
      minWidth: "auto",
    },
    "& .MuiPopover-paper": {
      width: "140px",
      boxShadow:
        "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
      border: "none",
    },
  },
  logout: {
    "& a": {
      position: "absolute",
      width: "170px",
      right: "0px",
      padding: "12px 15px",
      backgroundColor: "#fff",
      bottom: "-55px",
      fontSize: "15px",
      boxShadow:
        "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
      "&:hover": {
        background: "#f1f1f1",
      },
    },
  },
}));

const NavBar: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [alertCount, setalertCount] = React.useState<number>(0);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const { user } = useAuth();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    // localStorage.removeItem('sessionToken');
    setAnchorEl(!anchorEl);
  };

  useEffect(() => {
    GETalertCount().then((v) => setalertCount(v));
  }, [user?.role]);

  return (
    <div className={classes.root}>
      <Toolbar className={classes.navWrapper}>
        <div></div>
        <div className={classes.navActionsArea}>
          <IconButton style={{ marginRight: '5px' }}>
            <Link to="/alerts">
              <Badge badgeContent={alertCount} color="error" style={{ width: '35px' }}>
                <NotificationIcon />
              </Badge>
            </Link>
          </IconButton>

          <Typography color="primary">{user?.userName} </Typography>
          <div className={classes.btnLogout}>
            <Button onClick={handleClick}>
              <MoreVert />
            </Button>
            {anchorEl && (
              <div className={classes.logout}>
                <a
                  onClick={() => {
                    LogOut();
                    history.push("/login");
                  }}
                  style={{ textDecoration: "inherit" }}
                >
                  Logout{" "}
                  <span style={{ marginLeft: "70px", marginRight: "-10px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.0"
                      width="20px"
                      height="20px"
                      viewBox="0 0 512.000000 512.000000"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <g
                        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                        fill="#979797"
                        stroke="none"
                      >
                        <path d="M980 5105 c-324 -66 -577 -322 -636 -645 -20 -110 -20 -3689 0 -3800 30 -166 112 -316 239 -437 123 -116 265 -187 427 -212 55 -9 396 -11 1266 -9 l1191 3 49 30 c30 19 60 49 79 79 27 44 30 58 30 126 0 68 -3 82 -30 126 -19 30 -49 60 -79 79 l-49 30 -1211 5 -1211 5 -52 27 c-70 37 -120 89 -152 157 l-26 56 0 1835 0 1835 26 56 c32 68 82 120 152 157 l52 27 1211 5 1211 5 49 30 c30 19 60 49 79 79 27 44 30 58 30 126 0 68 -3 82 -30 126 -19 30 -49 60 -79 79 l-49 30 -1211 2 c-967 1 -1224 -1 -1276 -12z" />
                        <path d="M3304 3916 c-88 -41 -134 -118 -134 -222 1 -94 15 -113 418 -516 l376 -378 -958 0 c-724 0 -969 -3 -1000 -12 -86 -26 -166 -136 -166 -228 0 -92 80 -202 166 -228 31 -9 276 -12 1000 -12 l958 0 -376 -377 c-206 -208 -384 -394 -394 -413 -13 -25 -18 -57 -19 -111 0 -67 3 -81 30 -124 56 -91 166 -133 272 -105 45 12 90 54 656 619 356 354 617 623 630 646 30 55 29 155 0 210 -13 23 -276 293 -630 647 -657 655 -625 628 -733 628 -26 0 -66 -10 -96 -24z" />
                      </g>
                    </svg>
                  </span>
                </a>
              </div>
            )}
          </div>
        </div>
      </Toolbar>
    </div>
  );
};
export default NavBar;
