import React from 'react';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 10,
    position: 'absolute',
    margin: '0 auto',
    wordSpacing: 'nowrap',
    color: '#FFF',
    fontWeight: 600,
  },
  batteryRoot: {
    position: 'relative',
    textAlign: 'center',
    marginRight: 5,
    marginLeft: 1,
  },
}));

export const AddUserIcon = () => {
  const styles = useStyles();

  return (
    <div className={styles.batteryRoot}>
      <svg
        width="20"
        height="10"
        viewBox="0 0 20 10"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10 5.625C11.3583 5.625 12.5583 5.95 13.5333 6.375C14.4333 6.775 15 7.675 15 8.65V10H5V8.65833C5 7.675 5.56667 6.775 6.46667 6.38333C7.44167 5.95 8.64167 5.625 10 5.625ZM3.33333 5.83333C4.25 5.83333 5 5.08333 5 4.16667C5 3.25 4.25 2.5 3.33333 2.5C2.41667 2.5 1.66667 3.25 1.66667 4.16667C1.66667 5.08333 2.41667 5.83333 3.33333 5.83333ZM4.275 6.75C3.96667 6.7 3.65833 6.66667 3.33333 6.66667C2.50833 6.66667 1.725 6.84167 1.01667 7.15C0.4 7.41667 0 8.01667 0 8.69167V10H3.75V8.65833C3.75 7.96667 3.94167 7.31667 4.275 6.75ZM16.6667 5.83333C17.5833 5.83333 18.3333 5.08333 18.3333 4.16667C18.3333 3.25 17.5833 2.5 16.6667 2.5C15.75 2.5 15 3.25 15 4.16667C15 5.08333 15.75 5.83333 16.6667 5.83333ZM20 8.69167C20 8.01667 19.6 7.41667 18.9833 7.15C18.275 6.84167 17.4917 6.66667 16.6667 6.66667C16.3417 6.66667 16.0333 6.7 15.725 6.75C16.0583 7.31667 16.25 7.96667 16.25 8.65833V10H20V8.69167ZM10 0C11.3833 0 12.5 1.11667 12.5 2.5C12.5 3.88333 11.3833 5 10 5C8.61667 5 7.5 3.88333 7.5 2.5C7.5 1.11667 8.61667 0 10 0Z" />
      </svg>
    </div>
  );
};
export default AddUserIcon;
