import { create } from 'jss';
import rtl from 'jss-rtl';
import React from 'react';
import {
  jssPreset,
  MuiThemeProvider,
  StylesProvider,
} from '@material-ui/core/styles';
import MuiTheme from './mui';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

export const ThemeProvider = (props: { children: React.ReactChild }) => {
  return (
    <StylesProvider jss={jss}>
      <MuiThemeProvider theme={MuiTheme}>
        {React.Children.only(props.children)}
      </MuiThemeProvider>
    </StylesProvider>
  );
};
