import { MENU_SIZE } from 'utils/constants';

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    background: '#FFF',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '36px',
    color: '#939598',
    padding: theme.spacing(1),
  },

  wrapper: {},
  icon: {
    padding: `0 ${theme.spacing(2)}px`,
  },
  active: {
    background: '#F8666410',
    borderRight: '2px solid #F86664',
    color: '#4D4D5A',
    '& $icon': {
      color: '#F86664',
    },
  },
  menuListWrapper: {
    borderRight: '1px solid #EAEEF1',
    height: '100vh',
    zIndex: 999,
    // overflowY: 'scroll',
    position: 'fixed',
    minWidth: MENU_SIZE,

  },
  logoWrapper: {
    width: '100%',
    // display: 'flex',
    // alignItems: 'center',
    justifyContent: 'center',
    fontSize: '24px',
    textAlign: 'center',
    fontWeight: 600,
    color: '#f86664',
    padding: theme.spacing(2.4),
    '& span':{
      display: 'block',
      fontSize: '10px',
      fontWeight: 400,
      color: '#000',
    }
  },
  listContainer: {
    maxHeight: 'calc(100vh - 130px)',
    overflowY: 'scroll',
    paddingBottom: '40px',
    '&::-webkit-scrollbar': {
      width: '0px !important',
    },
  },
  label: {
    fontSize: 14,
  },
}));
