import { APP_API_URL } from "utils/constants";
import axios, { AxiosError } from "axios";
import moment from 'moment-timezone';

const axiosService = axios.create({
  baseURL: APP_API_URL + "/auth",
  headers: {
    'timezone': moment.tz.guess()
  } as never,
  withCredentials: true,
});

export async function Login(payload) {
  try {
    const res = await axios.post(`${APP_API_URL}/auth/login`, payload, {
      withCredentials: true,
    });
    return res.data;
  } catch (err) {
    if (err instanceof AxiosError) {
      throw new Error(err.response?.data.message);
    }
    throw err;
  }
}

export async function POSTconfirmOTP(payload) {
  try {
    const res = await axios.post(`${APP_API_URL}/auth/otp/confirm`, payload, {
      withCredentials: true,
    });
    return res.data;
  } catch (err) {
    if (err instanceof AxiosError) {
      throw new Error(err.response?.data.message);
    }
    throw err;
  }
}

export async function GETme() {
  try {
    const res = await axiosService.get("me");
    return res.data;
  } catch (err) {
    throw err;
  }
}
export async function PATCHsetPassword(payload) {
  try {
    const res = await axios.patch(`${APP_API_URL}/auth/setpassword`, payload);
    return res.data;
  } catch (error) {
    if (typeof error === "string") throw error;
    if (error instanceof AxiosError)
      throw (
        error.response?.data?.message ||
        error.message ||
        "failed setting new password"
      );
  }
}

export async function PATCHforgotPassword(email) {
  try {
    const res = await axios.patch(`${APP_API_URL}/auth/forgotpassword`, {
      email,
    });
    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export async function POSTuser(payload) {
  try {
    const res = await axiosService.post("register", payload);
    return res.data;
  } catch (err) {
    throw err;
  }
}

export async function LogOut() {
  await axiosService.get("logout");
  localStorage.clear();
}
