/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from 'utils/PrivateRoute';
import { UsersListView } from './UserListView/Loadable';
import { UserForm } from './UserForm/Loadable';
import { makeStyles, Grid } from '@material-ui/core';
import { StyleConstants } from 'styles/StyleConstants';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: StyleConstants.NAV_BAR_HEIGHT,
    background: '#fff',
  },
}));
export function UserPage({ match }) {
  const styles = useStyles();
  return (
    <>
      <Grid container className={styles.root}>
        <Switch>
          <PrivateRoute exact path={match.url} component={UsersListView} />
          <PrivateRoute
            exact
            path={match.url + '/create'}
            component={UserForm}
          />
          <PrivateRoute
            exact
            path={`${match.url}/:id/edit`}
            component={UserForm}
          />
          {/* <Route exact path={match.url} component={UsersListView} />
          <Route
            exact
            path={match.url + '/create'}
            component={UserForm}
          />
          <Route
            exact
            path={`${match.url}/:id/edit`}
            component={UserForm}
          /> */}
        </Switch>
      </Grid>
    </>
  );
}
