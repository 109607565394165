import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import {
  Ballot,
  CollectionsBookmark,
  Domain,
  Help,
  LocalOffer,
  Poll,
  Report
} from '@material-ui/icons';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import ReplySharpIcon from '@material-ui/icons/ReplySharp';
import { AddUserIcon } from './AddUserIcon';
import { SanitizerRoundedIcon } from './SanitizerRoundedIcon';
import { useStyles } from './styles';
import { useAuth } from 'services/hooks/auth';

const menuItems = [
  {
    name: 'Review Alerts',
    path: '/alerts',
    icon: <NotificationImportantIcon fontSize="small" />,
    role: 2,
  },
  {
    name: 'Manage Patients',
    path: '/patients',
    icon: <PeopleAltIcon fontSize="small" />,
    role: 2,
  },
  {
    name: 'Label Medications',
    path: '/medications-tagging',
    icon: <SanitizerRoundedIcon />,
    role: 2,
  },
  {
    name: 'Projects List',
    path: '/projects',
    icon: <CollectionsBookmark fontSize="small" />,
    role: 2,
  },
  {
    name: 'Add/Edit User',
    path: '/staff-users',
    icon: <AddUserIcon />,
    role: 2,
  },
  {
    name: 'Site List',
    path: '/sites',
    icon: <Domain fontSize="small" />,
    role: 1,
  },
  {
    name: 'Medication Database',
    path: '/medications',
    icon: <SanitizerRoundedIcon />,
    role: 1,
  },
  {
    name: 'Question Bank',
    path: '/questions',
    icon: <Ballot fontSize="small" />,
    role: 1,
  },
  {
    name: 'Survey Builder',
    path: '/surveys',
    icon: <Poll fontSize="small" />,
    role: 1,
  },
  {
    name: 'Patient Generated Data',
    path: '/survey-responses',
    icon: <ReplySharpIcon fontSize="small" />,
    role: 2,
  },
  {
    name: 'Label Database',
    path: '/label',
    icon: <LocalOffer />,
    role: 1,
  },
  {
    name: 'Analytics',
    path: '/analytics',
    icon: <Report />,
    role: 2,
  },
];

const MenuItem = props => {
  const classes = useStyles();
  const { name, path, icon } = props;
  return (
    <NavLink
      to={path}
      className={classes.root}
      activeClassName={classes.active}
    >
      <span className={classes.icon}>{icon ? icon : <Help />}</span>
      <Typography className={classes.label}>{name}</Typography>
    </NavLink>
  );
};

const DashboardMenu: FC = () => {
  const classes = useStyles();
  const { user } = useAuth();

  return (
    <div className={classes.menuListWrapper}>
      <div className={classes.logoWrapper}>
        Tappt Health.
        <span>Powered by Synchronyx.</span>
      </div>
      <NavLink
        exact
        className={classes.root}
        activeClassName={classes.active}
        to="/"
      >
        <Typography
          variant="h4"
          component={'h4'}
          style={{ fontWeight: 'bold', padding: '10px 20px', color: '#4D4D5A' }}
        >
          {user?.role === 1 ? 'Admin Dashboard' : 'Site Dashboard'}
        </Typography>
      </NavLink>
      <div className={classes.listContainer}>
        {menuItems.map((item, index) =>
          item.role === 2 || user?.role === item.role ? (
            <MenuItem {...item} key={index} />
          ) : (
            ''
          ),
        )}
      </div>
    </div>
  );
};
export default DashboardMenu;
