import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  exportExcel: {
    // textTransform: 'none',
    // background: '#F86664',
    // backgroundColor: '#F86664',
    // borderRadius: '4px',
    // color: '#ffffff',
    // fontSize: '14px',
    // width: '100px',
    // height: '48px',
    // margin: '24px',
    // '&:hover': {
    //   background: '#F86444',
    // },
  },
  button2: {
    background: '#DCDCDC',
    margin: 0,
    width: '100px',
    height: '48px',
    fontSize: '16px',
    lineHeight: '1',
    '&:hover': {
      background: '#d5d5d5',
    },
  },
  button1: {
    background: '#F86664',
    margin: 0,
    width: '100px',
    height: '48px',
    fontSize: '16px',
    color: '#fff',
    lineHeight: '1',
    marginLeft: 'auto !important',
    '&:hover': {
      background: '#ad4746',
    },
  },
}));
