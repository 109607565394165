import { createGlobalStyle } from 'styled-components';

/* istanbul ignore next */
export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
  }

  body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background-color: '#fff';
  }

  body.fontLoaded {
    // font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-family: 'Roboto', sans-serif;
  }
  a{
    color:#000;
  }
  p,
  label {
    line-height: 1.5em;
    font-size:14px;
    font-weight:500;
    color:#4D4D5A;
    text-align:left;
  }

  textarea{
    height: 124px;
    border: 1px solid #C4C4C4;
    border-radius: 4px;
  }
  // bg-orange{
  //   height:40px;
  //   background:#F86664;
  // }
  input, select, button {
    font-family: inherit;
    font-size: inherit;
  }

   img {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
   }


   ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #F86664;
  background-clip: padding-box;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #F86664;
}
::-webkit-input-placeholder {
  color: #4D4D5A !important;
}

:-moz-placeholder { /* Firefox 18- */
  color: #4D4D5A !important;
}

::-moz-placeholder {  /* Firefox 19+ */
  color: #4D4D5A !important;
}

:-ms-input-placeholder {
  color: #4D4D5A !important;
}
.MuiTableContainer-root::-webkit-scrollbar {
  width: 0px;
}
.MuiPickersCalendarHeader-iconButton{
  margin-right:10px
}
.MuiPickersCalendarHeader-iconButton:first-child{
  position:absolute;
  right:70px;
}
.MuiPickersCalendarHeader-transitionContainer{
  width:141px;
  color:#4D4D5A;
  font-weight:700;
  position:relative;
}
.MuiPickersCalendarHeader-transitionContainer:after {
	top: 7px;
	right: 0;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.MuiPickersCalendarHeader-transitionContainer:after {
	border-color: rgba(136, 183, 213, 0);
	border-top-color: #979797;
	border-width: 7px;
	margin-left: -7px;
}
.MuiPickersCalendarHeader-switchHeader{
  margin-top:10px;
}
.MuiPickersCalendarHeader-daysHeader{
  margin-top:25px;
}
.MuiPickersToolbarButton-toolbarBtn:first-child{
  position: absolute;
  bottom: -63px;
  left: 80px;
  z-index: 999;
  opacity: 0;
}
.MuiPickersToolbar-toolbar{
  min-height:40px;
  height:40px;
}
.MuiPickersToolbarText-toolbarBtnSelected{
  color:#fff;
  font-weight:400;
}
.MuiPopover-paper{
  margin-top:36px;
  box-shadow:none;
}
.MuiMenu-list{
  padding: 0px 12px;
  overflow-y: scroll;
  max-height: 200px;
}
.MuiPopover-paper{
  border: 1px solid #C4C4C4;
  // border-top: none;
  border-radius:0px 0px 4px 4px;
}
.MuiMenu-list .MuiMenuItem-root{
  padding: 8px 10px 8px 0px;
}
.MuiMenu-list .MuiMenuItem-root + .MuiMenuItem-root{
  border-top:1px solid #DEDEDE;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: #C4C4C4;
  border-width:1px;
}
.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover, .MuiListItem-button:hover{
  background-color:transparent;
}
`;
