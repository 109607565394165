/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import React from 'react';
import NavBar from 'app/components/NavBar';
import { Switch, Route } from 'react-router-dom';
import { AlertsPage } from '../Alerts/Loadable';
import DashboardMenu from 'app/components/DashboardMenu/index';
import { PatientsPage } from '../Patients';

import { MENU_SIZE } from 'utils/constants';
import { MedicationsPage } from '../Medications/Loadable';
import { MedicationTaggingsPage } from '../MedicationsTagging/Loadable';
import { ProjectsPage } from '../Projects/Loadable';
import { StaffUsersPage } from '../StaffUsers/Loadable';
import { SitesPage } from '../Sites/Loadable';
import { TagIdsPage } from '../TagsId/Loadable';
import { QuestionPage } from '../Questions/Loadable';
import { SurveysPage } from '../Surveys/Loadable';
import { SurveysResponsePage } from '../SurveysResponses/Loadable';
import { DashboardHome } from '../DashboardHome/loadable';
import { useAuth } from 'services/hooks/auth';


export function DashboardPage({ match }) {
  const { user } = useAuth();

  return (
    <>
      <div style={{ overflow: 'hidden' }}>
        <DashboardMenu />
        <div
          style={{
            paddingLeft: MENU_SIZE,
            overflowY: 'hidden',
            height: '100vh',
          }}
        >
          <NavBar />
          <div
            style={{
              paddingTop: 64,
              // paddingBottom: 150,
              overflowY: 'auto',
              maxHeight: '100vh',
              minHeight: '100vh',
            }}
          >
            <Switch>
              {user?.role === 1 ? (
                <>
                  <Route exact path={`/`} component={DashboardHome} />
                  <Route path={`/projects`} component={ProjectsPage} />
                  <Route path={`/sites`} component={SitesPage} />
                  <Route path={`/medications`} component={MedicationsPage} />
                  <Route path={`/label`} component={TagIdsPage} />
                  <Route path={`/questions`} component={QuestionPage} />
                  <Route path={`/surveys`} component={SurveysPage} />
                  <Route path={`/alerts`} component={AlertsPage} />
                  <Route path={`/patients`} component={PatientsPage} />
                  <Route
                    path={`/medications-tagging`}
                    component={MedicationTaggingsPage}
                  />
                  <Route path={`/staff-users`} component={StaffUsersPage} />

                  <Route
                    path={`/survey-responses`}
                    component={SurveysResponsePage}
                  />
                </>
              ) : (
                <>
                  <Route exact path={`/`} component={DashboardHome} />
                  <Route path={`/alerts`} component={AlertsPage} />
                  <Route path={`/patients`} component={PatientsPage} />
                  <Route path={`/projects`} component={ProjectsPage} />
                  <Route
                    path={`/medications-tagging`}
                    component={MedicationTaggingsPage}
                  />
                  <Route path={`/staff-users`} component={StaffUsersPage} />

                  <Route
                    path={`/survey-responses`}
                    component={SurveysResponsePage}
                  />
                </>
              )}
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
}
