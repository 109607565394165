import { colors, createMuiTheme } from '@material-ui/core';

import typography from './typography';

const theme = createMuiTheme({
  // direction: 'rtl',
  palette: {
    background: {
      default: colors.common.white,
      paper: colors.common.white,
    },
    primary: {
      main: '#F86664',
    },
    secondary: {
      main: colors.indigo[500],
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: '#4D4D5A',
    },
  },
  typography,
  overrides: {
    MuiButton: {
      containedPrimary: { color: '#FFF' },
    },
  },
});

export default theme;
