import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { PatientsListView } from './PatientsListView/Lodable';
import { Switch, Route } from 'react-router-dom';
import { PatientForm } from './PatientForm/Lodable';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
    background: '#FFF',
    position: 'relative',
  },
}));

export const PatientsPage = ({ match }) => {
  const styles = useStyles();



  return (
    <>
      <Grid container className={styles.root}>
        <Switch>
          <Route exact path={match.url} component={PatientsListView} />
          <Route exact path={`${match.url}/create`} component={PatientForm} />
          <Route exact path={`${match.url}/:id/edit`} component={PatientForm} />
        </Switch>
      </Grid>
    </>
  );
};
