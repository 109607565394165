import clsx from 'clsx';
import React from 'react';
import Button from '@material-ui/core/Button';
import { useStyles } from './styles';

const Buttonui = ({ label, ...rest }) => {
  const classes = useStyles();
  const { className, onClick } = rest;
  return (
    <Button className={clsx(classes.exportExcel, className)} onClick={onClick}>
      {label}
    </Button>
  );
};

export default Buttonui;
