import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  dialogBox:{
    '& .MuiDialog-paperWidthSm':{
      minWidth: '340px',
    },
    '& .MuiBackdrop-root':{
      backgroundColor: 'rgba(0, 0, 0, 0.1)'
    }
  },
  button2: {
    background: '#DCDCDC',
    margin: 0,
    width: '100px',
    height: '48px',
    fontSize: '16px',
    lineHeight: '1',
    textTransform: 'capitalize',
    '&:hover': {
      background: '#d5d5d5',
    },
  },
  button1: {
    background: '#F86664',
    margin: 0,
    width: '100px',
    height: '48px',
    fontSize: '16px',
    color: '#fff',
    lineHeight: '1',
    marginLeft: '150% !important',
    textTransform: 'capitalize',
    '&:hover': {
      background: '#ad4746',
    },
  },
  footer: {
    padding: '0px 20px 15px 20px',
    display: 'grid',
    gridTemplateColumns: '46% 46%',
    gridGap: '20px',
    marginTop: '15px',
  },
  title: {
    textAlign: 'left',
    fontSize: '20px',
    color: '#0F458D',
    marginBottom: '20px',
    borderBottom: '1px solid #939598',
    '& h2': {
      fontSize:'16px',
      lineHeight: '25px',
      color: '#4D4D5A',

    },
  },
  description: {
    textAlign: 'left',
    fontSize: '14px',
    color: '#3E4E6C',
  },
}));
