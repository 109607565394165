/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'sanitize.css/sanitize.css';
// Initialize languages
import './locales/i18n';

import { App } from 'app';
import FontFaceObserver from 'fontfaceobserver';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { HelmetProvider as HelmetProviderTrigger } from 'react-helmet-async';
import * as serviceWorker from 'serviceWorker';
import { ThemeProvider } from 'styles/theme/ThemeProvider';

import { ToastContainer, ToastContainerProps } from 'react-toastify';
import CssBaseline from '@material-ui/core/CssBaseline';
import { QueryClient, QueryClientProvider } from 'react-query';
import ReactGA from 'react-ga';



const HelmetProvider: any = HelmetProviderTrigger;

// Create a client
const queryClient = new QueryClient();

// Observe loading of Inter (to remove 'Inter', remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Open Sans', {});

// When Inter is loaded, add a font-family using Inter to the body
openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
  document.body.style.background = '#ffffff';
});
const toastProps: ToastContainerProps = {
  position: 'bottom-right',
  autoClose: 5000,
  theme: 'colored',
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

interface Props {
  Component: typeof App;
}




const ConnectedApp = ({ Component }: Props) => (
  <div>
    <CssBaseline />
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <HelmetProvider>
          <React.StrictMode>
            <Component />
            <ToastContainer {...toastProps} />
          </React.StrictMode>
        </HelmetProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </div>
);

const render = (Component: typeof App) => {

  ReactDOM.render(<ConnectedApp Component={Component} />, MOUNT_NODE);
};

if (module.hot) {
  // Hot reloadable translation json files and app
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./app', './locales/i18n'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    const App = require('./app').App;
    render(App);
  });
}

render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
serviceWorker.unregister();
