/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { AuthPage } from 'app/containers/Auth';
import { SetPassPage } from 'app/containers/SetPassword';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Route, Switch, } from 'react-router-dom';
import { GlobalStyle } from '../styles/global-styles';
import ReactGA from 'react-ga';
import PrivateRoute from '../utils/PrivateRoute';
import { DashboardPage } from './containers/Dashboard';
import { NotFoundPage } from './containers/NotFoundPage/Loadable';
import { UserPage } from './containers/User';
import { ForgotPassword } from './containers/Auth/ForgotPassword/Loadable';



const TRACKING_ID = 'G-YX9TQ8J0TM'; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID)

export function App() {
  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  return (
    <BrowserRouter>
      <Helmet titleTemplate="%s - Synchronyx" defaultTitle="Synchronyx">
        <meta name="description" content="A Synchronyx application" />
      </Helmet>
      <Switch>
        <Route exact path="/login" component={AuthPage} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/setpass/:token" component={SetPassPage} />
        <PrivateRoute path={'/'} component={DashboardPage} />
        <PrivateRoute path="/users" component={UserPage} />
        {/* <Route path={'/'} component={DashboardPage} />
        <Route path="/users" component={UserPage} /> */}

        <Route path="/no-access" component={NotFoundPage} />
      </Switch>
      <GlobalStyle />
    </BrowserRouter>
  );
}
