import { LoadingIndicator } from "app/components/LoadingIndicator";
import React, { useState } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { useAuth } from "services/hooks/auth";
import styled from "styled-components";
import { useIdleTimer } from "react-idle-timer";
import { LogOut } from "services/api/auth";
import IdleDialog from "app/components/IdleUserModal";

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function PrivateRoute({ component: Component, ...rest }) {
  const { isLoggedIn, isLoading, user } = useAuth();
  const [isUserIdle, setIsUserIdle] = useState(false);

  const onIdle = () => {
    setIsUserIdle(true);
    LogOut();
  };
  const history = useHistory();
  const adminIdleTimeOut = 1000 * 60 * 60 * 24;
  const siteUserIdleTimeOut = 1000 * 60 * 30;
  useIdleTimer({
    onIdle,
    timeout: user?.role === 1 ? adminIdleTimeOut : siteUserIdleTimeOut,
  });
  if (isLoading)
    return (
      <LoadingWrapper>
        <LoadingIndicator />
      </LoadingWrapper>
    );

  if (!isLoggedIn && !isUserIdle) return <Redirect to="/login" />;
  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <IdleDialog
            open={isUserIdle}
            handleConfirm={() => {
              LogOut();
              history.push("/login");
            }}
          />
          <Component {...props} />
        </>
      )}
    />
  );
}
