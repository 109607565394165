/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { LoginPage } from './SetPassPage/Loadable';

export function SetPassPage({ match }) {
  return (
    <>
      <LoginPage />
    </>
  );
}
