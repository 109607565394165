import { useQuery } from 'react-query';
import { GETme } from 'services/api/auth';
import { z } from 'zod';
const UserData = z.object({
  role: z.number().min(1).max(2),
  firstName: z.string(),
  lastName: z.string(),
  site: z.object({ id: z.number() }),
});
const ParsedUser = z.object({
  role: z.number().min(1).max(2),
  userName: z.string(),
  site: z.object({ id: z.number() }),
});
type BasicUser = z.infer<typeof ParsedUser>;
export const useAuth = () => {
  const { data, isLoading, isError, error, isSuccess } = useQuery<BasicUser>(
    ['auth-me'],
    async () => {
      const user = await GETme();
      const parsedUser = UserData.parse(user);
      const { firstName, lastName } = parsedUser;
      return { userName: firstName + ' ' + lastName, ...parsedUser };
    },
  );

  return { isLoading, user: data, isLoggedIn: isSuccess, isError, error };
};
